import React from 'react';
import MainWrapper, {LogoImageContainer, MainContentSection} from "@deadline/common/ui/five.style";
import {Container} from "../components/Container";
import styled from "styled-components";
import LogoImage from '@deadline/common/static/images/logoWhite.svg';
import {Link} from "gatsby";
import {SEO} from "../components";
import Helmet from 'react-helmet';

const TextWrapper = styled.div`
  margin-top: 100px;
  background: #fff;
  padding: 50px;
  border-radius: 5px;
  width: 100%;
  *{
    max-width: initial !important;
    text-align: justify !important;
     font-family: 'Roboto' !important;
    
  }
`;


function ErrorPage() {
    return (
        <MainWrapper>
            <Helmet>
                <title>
                    Simplicity Commerce | Data Processors
                </title>
            </Helmet>
            <Container className="mainContainer">
                <MainContentSection>
                    <LogoImageContainer>
                        <Link to={'/'}>
                            <img src={LogoImage} alt="logo" />
                        </Link>
                    </LogoImageContainer>

                    <TextWrapper>
                        <h1>Data Processors</h1>
                        <div className="col-sm-8 mx-auto">
                            <p>Simplicity Commerce has signed Data Processing Agreements (or has agreed to Data Processing
                                Addendums) with the following companies:</p>
                            <table>
                                <thead>
                                <tr>
                                    <th>Company</th>
                                    <th>Country</th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr>
                                    <td>AdRoll Group</td>
                                    <td>Ireland</td>
                                </tr>
                                <tr>
                                    <td>Amazon Web Services, Inc.</td>
                                    <td>US</td>
                                </tr>
                                <tr>
                                    <td>Atlassian, Inc.</td>
                                    <td>US</td>
                                </tr>
                                <tr>
                                    <td>Braintree</td>
                                    <td>US</td>
                                </tr>
                                <tr>
                                    <td>Bugsnag, Inc.</td>
                                    <td>US</td>
                                </tr>
                                <tr>
                                    <td>Cloudflare, Inc.</td>
                                    <td>US</td>
                                </tr>
                                <tr>
                                    <td>CrowdCast Inc</td>
                                    <td>US</td>
                                </tr>
                                <tr>
                                    <td>Digital Crew Limited</td>
                                    <td>Ireland</td>
                                </tr>
                                <tr>
                                    <td>Facebook</td>
                                    <td>US</td>
                                </tr>
                                <tr>
                                    <td>Google LLC.</td>
                                    <td>US</td>
                                </tr>
                                <tr>
                                    <td>Hotjar Ltd.</td>
                                    <td>Malta</td>
                                </tr>
                                <tr>
                                    <td>Hubspot, Inc.</td>
                                    <td>US</td>
                                </tr>
                                <tr>
                                    <td>Intercom, Inc.</td>
                                    <td>US</td>
                                </tr>
                                <tr>
                                    <td>Jotform Inc.</td>
                                    <td>US</td>
                                </tr>
                                <tr>
                                    <td>Klipfolio Inc.</td>
                                    <td>Canada</td>
                                </tr>
                                <tr>
                                    <td>LogMeIn USA, Inc.</td>
                                    <td>US</td>
                                </tr>
                                <tr>
                                    <td>Mailgun Technologies, Inc.</td>
                                    <td>US</td>
                                </tr>
                                <tr>
                                    <td>Mavenlink, Inc.</td>
                                    <td>US</td>
                                </tr>
                                <tr>
                                    <td>New Relic, Inc.</td>
                                    <td>US</td>
                                </tr>
                                <tr>
                                    <td>Pandadoc, Inc.</td>
                                    <td>US</td>
                                </tr>
                                <tr>
                                    <td>Polymail Inc.</td>
                                    <td>US</td>
                                </tr>
                                <tr>
                                    <td>Rackspace, US Inc.</td>
                                    <td>US</td>
                                </tr>
                                <tr>
                                    <td>RingCentral, Inc.</td>
                                    <td>US</td>
                                </tr>
                                <tr>
                                    <td>Salesforce.com, Inc.</td>
                                    <td>US</td>
                                </tr>
                                <tr>
                                    <td>SendGrid, Inc.</td>
                                    <td>US</td>
                                </tr>
                                <tr>
                                    <td>Slack Technologies</td>
                                    <td>US</td>
                                </tr>
                                <tr>
                                    <td>Spreedly, Inc.</td>
                                    <td>US</td>
                                </tr>
                                <tr>
                                    <td>Stripe Payments Europe, LTD</td>
                                    <td>US</td>
                                </tr>
                                <tr>
                                    <td>Twitter International Company</td>
                                    <td>US</td>
                                </tr>
                                <tr>
                                    <td>Typeform, S.L.</td>
                                    <td>Spain</td>
                                </tr>
                                <tr>
                                    <td>Unbounce Marketing Solutions, Inc.</td>
                                    <td>US</td>
                                </tr>
                                <tr>
                                    <td>Wrike, Inc.</td>
                                    <td>US</td>
                                </tr>
                                <tr>
                                    <td>YouCanBookMe Limited</td>
                                    <td>UK</td>
                                </tr>
                                <tr>
                                    <td>Zapier, Inc.</td>
                                    <td>US</td>
                                </tr>
                                <tr>
                                    <td>Zendesk Inc.</td>
                                    <td>US</td>
                                </tr>
                                <tr>
                                    <td>Wildbit, LLC</td>
                                    <td>US</td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                    </TextWrapper>
                </MainContentSection>
            </Container>
        </MainWrapper>
    );
}

export default ErrorPage;
